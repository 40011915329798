

















import {
  defineComponent, inject, ref, toRefs, watch,
} from 'vue-demi'

import ReviewCard from '@/components/ReviewCard.vue'
import { useStore } from '@/store'
import { ACTIONS, MODULES } from '@/store/types'
import { Payload } from '@/types/store'

export default defineComponent({
  components: {
    ReviewCard,
  },

  props: {
    company: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    let { company } = toRefs(props)
    const store = useStore()
    if (!company) {
      const injectedCompany: any = inject('company')
      company = ref(() => ({}))
      company.value = injectedCompany.value
    }

    const reviews = ref([])

    async function getReviewsList(id: string) {
      return store.dispatch<Payload<any>>({
        type: `${MODULES.Reviews}/${ACTIONS.Reviews.fetchReviewsList}`,
        id,
      })
    }

    watch(
      company,
      async () => {
        if (company.value) {
          const res = await getReviewsList(company.value.id)
          reviews.value = res[0].reviews.results
        }
      },
      { deep: true, immediate: true },
    )

    return { reviews }
  },
})

