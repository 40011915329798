



























import {
  defineComponent, ref, onMounted,
} from 'vue-demi'
import CompanyReviews from '@/components/views/CompanyReviews.vue'

import { companiesProvider } from '@/providers/companies'

export default defineComponent({
  components: {
    CompanyReviews,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    commentsDialog: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(true)

    const company = ref({})

    onMounted(() => {
      companiesProvider.getOne({ id: props.companyId }).then(res => {
        company.value = res
        isLoading.value = false
      })
    })

    function toggleCommentsDialog() {
      emit('on-comments-dialog-toggle')
    }

    return {
      company,
      isLoading,

      toggleCommentsDialog,
    }
  },

})
