



























































import {
  defineComponent, ref, watch,
} from 'vue-demi'

import CreateOrderWidget from '@/components/widgets/CreateOrderWidget.vue'
import MerketplaceItemSelected from '@/components/MarketplaceItemSelected.vue'

export default defineComponent({
  components: {
    CreateOrderWidget,
    MerketplaceItemSelected,
  },
  props: {
    selectedMarketplaceItems: {
      type: Array,
      required: true,
    },
    selectedCompaniesDialog: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentSelectedItems: any = ref(props.selectedMarketplaceItems)
    const disabled = ref(false)
    const modal = ref(false)

    function getTextForSelectedMarketplaceItemsCount() {
      const stringNum = `${props.selectedMarketplaceItems.length}`
      const lastNum = stringNum[stringNum.length - 1]
      if (props.selectedMarketplaceItems.length === 0) {
        return 'Не выбраны исполнители'
      }
      if (lastNum === '1') {
        return `${props.selectedMarketplaceItems.length} исполнитель`
      }
      if (lastNum === '2' || lastNum === '3' || lastNum === '4') {
        return `${props.selectedMarketplaceItems.length} исполнителя`
      }
      return `${props.selectedMarketplaceItems.length} исполнителей`
    }

    function filterSelectedItems(event: any) {
      if (!event.val) {
        console.log('setting')
        currentSelectedItems.value = currentSelectedItems.value.map((elem: any) => {
          elem.id === event.company.id ? elem.hidden = true : ''
          return elem
        })
      } else {
        currentSelectedItems.value = currentSelectedItems.value.map((elem: any) => {
          elem.id === event.company.id ? elem.hidden = false : ''
          return elem
        })
      }
    }

    function toggleSelectedCompaniesDialog() {
      emit('on-selected-companies-dialog-toggle')
    }

    function proceedOrderCreation() {
      modal.value = true
    }

    watch(() => currentSelectedItems.value,
      () => {
        console.log('watch')
        const current = currentSelectedItems.value.filter((elem: any) => elem.hidden !== true)
        console.log(current)
        console.log(current.length)
        disabled.value = !current.length
      },
      { deep: true, immediate: true })

    return {
      disabled,
      currentSelectedItems,
      modal,

      getTextForSelectedMarketplaceItemsCount,
      toggleSelectedCompaniesDialog,
      filterSelectedItems,
      proceedOrderCreation,
    }
  },

})
