












































































































import {
  reactive,
  defineComponent,
} from 'vue-demi'

interface Props extends Record<string, any> {
  readonly service: Record<string, any>
}

export default defineComponent<Props>({
  components: {},

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const state = reactive({})

    emit('setup')

    return {
      state,
    }
  },
})

