



































import {
  defineComponent, ref,
} from 'vue-demi'

export default defineComponent({
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const checkbox = ref(true)

    function onItemCheckboxClick() {
      emit('on-marketplace-selected-item-click', { company: props.company, val: checkbox.value })
    }

    return {
      checkbox,
      onItemCheckboxClick,
    }
  },

})
