







import {
  reactive,
  defineComponent,
} from 'vue-demi'

interface Props extends Record<string, any> {
  foo: string
}

export default defineComponent<Props>({
  components: {},

  props: {},

  setup(props, { emit }) {
    const state = reactive({})

    emit('setup')

    return {
      state,
    }
  },
})

