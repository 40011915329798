











































































































































import { reactive, defineComponent, inject } from 'vue-demi'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'

import ServiceCard from '@/components/ServiceCard.vue'
import SmartEnergyWindow from '@/components/SmartEnergyWindow.vue'

export default defineComponent({
  components: {
    ServiceCard,
    FadeTransition,
    SmartEnergyWindow,
  },

  setup() {
    const state = reactive({})

    const serviceList: any[] = [
      {
        image: 'amelia',
        title: 'Amelia',
        description: 'Cистема для управления эксплуатацией недвижимости',
      },
      {
        image: 'smartenergy',
        title: 'SmartEnergy',
        description: 'Продукт для определения тарифа потребления электроэнергии',
      },
      {
        image: 'sbertransport',
        title: 'Сбертранспорт',
        description: 'Продукт для управления грузоперевозками и заказа Такси',
      },
      {
        image: 'umka',
        title: 'УМКА (СТДУ)',
        description: 'Программа для управления датчиками температуры влажности офиса и здания',
      },
      {
        image: 'ipriemka',
        title: 'iПриёмка',
        description: 'Удаленный контроль качества и объёма оказанных услуг по клинингу',
      },
      {
        image: 'green_cert',
        title: 'Зеленый сертификат',
        description: 'Электроэнергия «Зеленых» генераторов',
      },
      // {
      //   image: 'reestr',
      //   title: 'Реестр объектов недвижимости',
      //   description: 'Кадастровый учет, переход права на ОН подтверждаются выпиской из ЕГРН.',
      // },
      // {
      //   image: 'isu',
      //   title: 'ИСУ',
      //   description: 'Управляет определением отклонений назначает автомат. задачи на исполнителей',
      // },
      // {
      //   id: 3,
      //   image: 'contract',
      //   title: 'Контрактование',
      //   description: 'Управления процессом закрепления и согласования офисных площадей',
      // },
      // {
      //   image: 'navigator',
      //   title: 'Навигатор',
      //   description: 'Рейтинг объекта, посчитанный по определенной методике отображает на карте',
      // },
      // {
      //   image: 'geomark',
      //   title: 'Геомаркетинг',
      //   description: 'Сокращение издержек на аренду бизнес офисов. Точки обслуживания клиентов',
      // },
    ]

    const isTablet = inject<boolean>('isTablet')
    const isNotTablet = inject<boolean>('isNotTablet')

    return {
      state,
      isTablet,
      isNotTablet,
      serviceList,
    }
  },
})

