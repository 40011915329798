






























































































































































import {
  reactive,
  defineComponent,
} from 'vue-demi'

export default defineComponent({
  components: {},

  props: {},

  setup() {
    const state = reactive({
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    })

    return {
      ...state,
    }
  },
})

