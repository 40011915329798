













































































import {
  defineComponent, ref,
} from 'vue-demi'

export default defineComponent({
  setup(props, { emit }) {
    const checkbox = ref(false)
    const search = ref('')

    function onCheckboxAllClick() {
      checkbox.value = !checkbox.value
      console.log('checkbox')
      emit('on-checkbox-all-click')
    }
    function toggleFiltersDrawer() {
      emit('on-filters-drawer-open')
    }
    function emitSearchEvent() {
      emit('on-search-input', search.value)
    }
    function selectCompanies() {
      emit('on-select-comapnies')
    }

    return {
      checkbox,
      search,

      onCheckboxAllClick,
      toggleFiltersDrawer,
      emitSearchEvent,
      selectCompanies,
    }
  },
})
