










































































































































































































































































































import {
  ref,
  defineComponent,
  nextTick,
  computed,
  onMounted,
  watch,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { useI18n } from '@/i18n'
import isIntoView from '@/utils/isIntoView'
import { rules } from '@/utils/validators'

// @ts-ignore
import { FadeTransition } from 'vue2-transitions'
import TheMarketplaceSearchInput from '@/components/TheMarketplaceSearchInput.vue'
import TheMarketplaceCompanyCommentsDialog from '@/components/dialogs/TheMarketplaceCompanyCommentsDialog.vue'
import TheMarketplaceSelectedCompaniesDialog from '@/components/dialogs/TheMarketplaceSelectedCompaniesDialog.vue'
import VCurrencyField from '@/components/widgets/VCurrencyField.vue'
import CreateOrderWidget from '@/components/widgets/CreateOrderWidget.vue'

// @ts-ignore
import StarRating from 'vue-star-rating'
import { useStore } from '@/store'
import { workTypesRepository } from '@/repositories'
import { Payload } from '@/types/store'
import { ACTIONS, MODULES } from '@/store/types'
import { Routes } from '@/router'

interface Props extends Record<string, any> {
  readonly id: string
  readonly section: string
}

export default defineComponent<Props>({
  components: {
    StarRating,
    FadeTransition,
    TheMarketplaceSearchInput,
    TheMarketplaceCompanyCommentsDialog,
    TheMarketplaceSelectedCompaniesDialog,
    VCurrencyField,
    CreateOrderWidget,
  },
  props: {
  },

  setup() {
    const i18n = useI18n()
    const toast = useToast()

    const store = useStore()

    const checkbox = ref(false)
    const commentsDialog = ref(false)
    const selectedCompaniesDialog = ref(false)
    const filtersDrawer = ref(false)
    const isCreateWidgetDialogVisible = ref(false)

    const activeCompanyId = ref()
    const clickedCompany = ref()

    const search = ref('')
    const filtersMobile = ref()
    const filtersDesktop = ref()

    const workTypes = ref()
    const marketplaceItems = computed(() => store.state.Marketplace.list)
    const page = ref(1)
    const lastQueryId = ref<any>(undefined)

    onMounted(async () => {
      await fetchWorksList()
    })

    async function setScrollListener() {
      document.querySelector('.v-application--wrap')?.addEventListener('scroll', setScrollListener)
      if (document.querySelectorAll('.marketplace__item')[document.querySelectorAll('.marketplace__item').length - 3] && isIntoView(document.querySelectorAll('.marketplace__item')[document.querySelectorAll('.marketplace__item').length - 3])) {
        document.querySelector('.v-application--wrap')?.removeEventListener('scroll', setScrollListener)

        const previousLastQueryId = lastQueryId.value
        page.value += 1
        await fetchMarketplaceItems(page.value, filtersMobile.value ? filtersMobile.value : filtersDesktop.value)

        nextTick(() => {
          if (previousLastQueryId !== lastQueryId.value) {
            document.querySelector('.v-application--wrap')?.addEventListener('scroll', setScrollListener)
          }
        })
      }
    }

    async function fetchWorksList() {
      try {
        const result = await workTypesRepository.findManyWithWorks({})
        workTypes.value = result.filter(type => type.works?.length)
        console.log(workTypes.value)
      } catch (error) {
        toast.error(`fetchWorksList: ${error}`)
      }
    }

    async function fetchMarketplaceItems(pageQ: number, work_id = '') {
      return store.dispatch<Payload<any>>({
        type: `${MODULES.Marketplace}/${ACTIONS.Marketplace.fetchMarketplaceItems}`,
        search: search.value,
        work_id,
        page: pageQ,
      })
    }

    function showCommentsDialog(companyId: number) {
      activeCompanyId.value = companyId
      commentsDialog.value = true
    }

    function getCompanyRoute(company: any) {
      if (!company) { return {} }

      return {
        name: Routes.of.Companies.OVERVIEW,
        params: { id: company.id.toString() },
      }
    }

    function filterSelectedItems() {
      if (checkbox.value) {
        return marketplaceItems.value
      }
      return marketplaceItems.value.filter((elem: any) => elem.checked === true)
    }

    function checkIfLengthIsNotNull() {
      if (filterSelectedItems().length !== 0 && selectedCompaniesDialog.value) {
        return true
      }
      selectedCompaniesDialog.value = false
      return false
    }

    function onChooseCompanyClick(company: any) {
      clickedCompany.value = company
      isCreateWidgetDialogVisible.value = true
    }

    async function onFiltersInput(where: string) {
      page.value = 1
      if (where === 'mobile') {
        await fetchMarketplaceItems(1, filtersMobile.value)
        await setScrollListener()
      } else {
        await fetchMarketplaceItems(1, filtersDesktop.value)
        await setScrollListener()
      }
      await setScrollListener()
    }

    function onSelectCompanies() {
      if (filtersMobile.value || filtersDesktop.value) selectedCompaniesDialog.value = true
    }

    function onCheckboxAllClick() {
      checkbox.value = !checkbox.value
      marketplaceItems.value.map(elem => elem.checked = checkbox.value)
    }

    function onItemCheckboxClick(item: any) {
      item.checked = !item.checked
    }

    async function onSearchInput(event: string) {
      search.value = event
      page.value = 1
      lastQueryId.value = -1
      await fetchMarketplaceItems(1, filtersMobile.value ? filtersMobile.value : filtersDesktop.value)
      await setScrollListener()
    }

    watch(
      () => filtersDesktop.value,
      async () => onFiltersInput('desktop'),
    )

    watch(
      () => filtersMobile.value,
      async () => onFiltersInput('mobile'),
    )

    return {
      i18n,
      checkbox,
      rules,
      filtersDrawer,
      commentsDialog,
      selectedCompaniesDialog,
      activeCompanyId,
      workTypes,
      marketplaceItems,
      filtersMobile,
      filtersDesktop,
      isCreateWidgetDialogVisible,
      clickedCompany,

      showCommentsDialog,
      onCheckboxAllClick,
      onItemCheckboxClick,
      onSearchInput,
      filterSelectedItems,
      onSelectCompanies,
      checkIfLengthIsNotNull,
      onFiltersInput,
      onChooseCompanyClick,
      getCompanyRoute,
    }
  },
})

